import { graphql } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"
import Prism from "prismjs"
import React from "react"
import { GoHome } from "../components/Buttons"
import CommentBox from "../components/CommentBox"
import { Container } from "../components/Container"
import SEO from "../components/seo"
import { Title } from "../components/StyledText"
import Layout from "../containers/Layout"

class BlogPost extends React.Component {
  componentDidMount() {
    Prism.highlightAll()
  }

  render() {
    const post = this.props.data.contentfulBlogPost
    const { html, excerpt } = post.body.childMarkdownRemark
    post.date = post.createdAt
    return (
      <Layout>
        <SEO
          title={post.title}
          description={post.description}
          image={post.image.fluid.src}
          meta={excerpt}
          createdAt={post.createdAt}
          updatedAt={post.updatedAt}
          isBlog
        />
        <Container fluid>
          <Img
            className="w-full"
            fluid={post.image.fluid}
            alt={post.title}
            style={{ height: 200 }}
          />
          <div style={{ padding: "2em 0em" }}>
            <Title post={post} />
            <hr className="my-8" />
            <div
              className="blog-body"
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </div>
          <CommentBox
            article={{
              url: this.props.location.href,
              id: post.date,
              title: post.title,
            }}
          />
          <GoHome isFullSize>
            <FormattedMessage id="goBack" />
          </GoHome>
        </Container>
      </Layout>
    )
  }
}

export default BlogPost

export const query = graphql`
  query PostQuery($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      createdAt(fromNow: false)
      updatedAt
      id
      description
      title
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 280)
        }
      }

      image {
        fluid(maxWidth: 786) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
