import Disqus from "disqus-react"
import React from "react"

const style = {
  container: { display: "flex", flexDirection: "row" },
  flexMain: { flex: 2 },
  flexSec: { flex: 1 },
}

const CommentBox = props => {
  const disqusShortname = "omarjs"
  const disqusConfig = {
    url: props.article.url,
    identifier: props.article.id + "0021454897",
    title: props.article.title,
  }

  return (
    <div className="article">
      <div style={style.container}>
        <div style={style.flexMain}>
          <h6>{props.article.title}</h6>
        </div>
        <div style={style.flexSec}>
          <Disqus.CommentCount
            shortname={disqusShortname}
            config={disqusConfig}
          >
            Commentarios
          </Disqus.CommentCount>
        </div>
      </div>
      <Disqus.DiscussionEmbed
        shortname={disqusShortname}
        config={disqusConfig}
      />
    </div>
  )
}

export default CommentBox
