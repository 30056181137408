import React from "react"
import moment from "moment"
import { FormattedMessage } from "gatsby-plugin-intl"

const Title = ({ post }) => {
  return (
    <div className="text-center">
      <p className="text-gray-500 text-lg text-base">
        <DateIntl date={post.createdAt} />
      </p>
      <h1 className="md:text-5xl xs:text-2xl font-bold">{post.title}</h1>
    </div>
  )
}

const DateIntl = ({ date, lang }) => {
  moment.locale("es", {
    monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Agos_Sept_Oct_Nov_Dic".split("_"),
    relativeTime: {
      future: "en %s",
      past: "Hace %s",
      s: "algunos segundos",
      m: "une minuto",
      mm: "%d minutos",
      h: "une hora",
      hh: "%d horas",
      d: "un día",
      dd: "%d días",
      M: "un mes",
      MM: "%d meses",
      y: "un año",
      yy: "%d años",
    },
  })

  const getLang = lang => (lang === "EN" ? "es" : "en")
  const getFormatedDate = text =>
    moment(date)
      .locale(lang || getLang(text))
      .format("ll")
  //.fromNow()

  return (
    <FormattedMessage id={"changeLanguage"}>{getFormatedDate}</FormattedMessage>
  )
}

const SectionTitle = ({ children, borderColor = "white" }) => (
  <h1
    style={{
      fontFamily: "Merriweather Sans",
      textDecoration: "underline",
      textDecorationColor: borderColor,
    }}
  >
    {children}
  </h1>
)

export { Title, SectionTitle, DateIntl }
